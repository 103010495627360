@import "@angular/cdk/overlay-prebuilt.css";

html {
  scroll-behavior: smooth;
}

#masthead-black-bar,
#global-footer {
  background: #54585a;
}

#global-footer .readmore {
  display: none;
}

.pae-x-axis {
  margin: 326px 0 0 55px;
  position: absolute;
}

.pae-y-axis {
  margin: 5px 0 0 30px;
  position: absolute;
}

.pae-axis-box .tick {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.pae-axis-box .domain,
.pae-axis-box line {
  display: none;
}

a:not(.vf-button) {
  box-shadow: none;
}

@media screen and (max-width: 39.9375em) {
  .pae-axis-box .tick {
    font-weight: 500;
    font-size: 10px;
  }
  .pae-x-axis {
    margin: 301px 0 0 36px;
  }

  .pae-y-axis {
    margin: 9px 0 0 13px;
  }
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dmsans/v6/rP2Hp2ywxg089UriCZOIHQ.woff2)
    format("woff2");
}

.af-footer {
  background-color: #f3f3f3;
}

.af-footer > div > div {
  height: 40px;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.af-footer-logos {
  text-align: right;
}

.af-footer-logos > a {
  text-decoration: none;
  border-bottom: none;
  display: inline-block;
}

.af-footer-logos > a:last-child {
  margin-left: 50px;
}

.af-footer-logos img {
  height: 40px;
}

.af-footer-text {
  font-weight: 500;
  font-size: 19px;
}

.faq-content a {
  border-bottom: none;
  color: #3b6fb6 !important;
}

.faq-content a:hover {
  text-decoration-line: underline;
  border-bottom: none;
  color: #1d477f !important;
}

.faq-content li {
  margin-bottom: 15px;
}

.faq-circle {
  list-style-type: circle !important;
}
.faq-circle-bullet {
  margin-bottom: 0px !important;
}

.monospace {
  font-family: monospace;
}

.for-big {
  display: block;
}

.for-mobile {
  display: none;
}

@media screen and (max-width: 39.9375em) {
  .for-big {
    display: none;
  }

  .for-mobile {
    display: block;
  }

  .af-footer > div > div {
    height: 23px;
    line-height: 23px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .af-footer-text {
    padding-right: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .af-footer-logos {
    padding-left: 0;
  }

  .af-footer-logos img {
    height: 25px;
  }

  .af-footer-logos > a:last-child {
    margin-left: 12px;
  }
}

a.ext {
  margin-left: 10px;
}

a.ext:after {
  content: " x";
  font-family: "EBI-Generic";
  font-size: 0.65rem;
  position: relative;
  top: -1px;
  margin-left: 4px;
}

.msp-plugin .msp-viewport {
  background: #fff !important;
}

.msp-plugin .msp-layout-standard {
  border: none !important;
}

.structureTable tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #ffffff !important;
}

.structureTable tbody tr:nth-child(1) {
  border-bottom: 0;
  background-color: #ffffff !important;
}

.msp-plugin .msp-layout-standard-outside .msp-layout-top {
  border-bottom: none !important;
}

.ms-pae-selection {
  background-color: #1affbb;
}

.ms-pae-select-green {
  background-color: #1affbb;
}

.ms-pae-select-orange {
  background-color: #ffa500;
}

.mmseqTable tbody tr:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.mat-mdc-tab-labels {
  border-bottom: 1px solid #d0d0ce !important;
}

.mdc-tab-indicator__content--underline {
  display: none;
}

.mdc-tab--active.mdc-tab-indicator--active {
  border-bottom: 3px solid #3b6fb6 !important;
}

.mdc-tab__text-label {
  font-size: 19px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28.5px !important;
  color: #1a1c1a !important;
}

.mdc-tab--active .mdc-tab__text-label {
  font-size: 19px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28.5px !important;
  color: #3b6fb6 !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-row,
.mat-mdc-footer-row {
  font-size: 1rem;
  font-weight: normal;
  font-family: "IBM Plex Sans";
  border-bottom: 1px solid #d0d0ce;
}

.mat-mdc-table thead,
.mat-mdc-header-cell,
.mat-mdc-header-row {
  border-bottom: 3px solid #000000;
}

.mat-mdc-icon-button:disabled {
  opacity: 0.38 !important;
}

.mat-mdc-icon-button {
  color: #373a36 !important;
  height: auto !important;
  padding: 0 !important;
}
.mat-mdc-tab-body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab:hover {
  border-radius: var(--similar-clusters-table-description-max, 0px);
  border-bottom: 3px solid var(--neutral-600, #707372);
  background: #fff;
}

.mat-mdc-tab.mdc-tab__content:hover {
  font-weight: bold;
}

.mat-tab-label:hover:not(.mat-tab-disabled) {
  background-color: rgb(206, 206, 206);
}

.mdc-notched-outline__trailing {
  border-top: none !important;
}

/* mdc-tab mat-mdc-tab mat-mdc-focus-indicator ng-star-inserted */

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
  border-bottom-right-radius: none !important;
  border-bottom-left-radius: none !important;
}

.mdc-notched-outline__leading {
  border-left: none !important;
  border-right: none !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
}

.mat-mdc-paginator .mat-mdc-select {
  border-bottom: 1px solid #000000;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-label {
  color: #373a36 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16.5px !important;
}
#mat-paginator-page-size-label-2,
.mat-mdc-paginator-range-label,
.mat-mdc-select-min-line {
  color: #373a36 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16.5px !important;
}
.mat-mdc-option.mdc-list-item {
  min-height: 35px;
}

.mat-mdc-paginator .mat-mdc-select-arrow svg {
  fill: #373a36 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: #f2f2f2;
  min-height: 35px;
}

.mat-mdc-option:hover {
  background-color: #f2f2f2;
}

.mdc-menu-surface.mat-mdc-select-panel {
  width: 74% !important;
  margin-left: 10px !important;
  border: 1px solid #d0d0ce !important;
  padding: 0px !important;
  background: white;
}

.mmseqTable .mobile-view {
  display: none;
  margin-right: 20px;
}

.mmseqTable .badgeTitleSvg {
  padding-right: 5px;
}

.mmseqTable a {
  color: #3b6fb6 !important;
  text-decoration: none !important;
  border-bottom: none !important;
  display: block;
  width: 162px;
}

.mmseqTable a:hover {
  color: #3b6fb6 !important;
  text-decoration: underline !important;
}

.mmseqTable .mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid #d0d0ce;
}

.mmseqTable .mdc-data-table__cell {
  vertical-align: top;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: visible;
  padding-left: 8px;
  padding-right: 8px;
}
.mat-mdc-paginator-range-label {
  margin: 0;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}

.mat-mdc-table .mat-mdc-header-cell {
  background: inherit;
  font-weight: 600 !important;
  text-align: left;
  font-size: 16px !important;
  padding: 0 3px 0 8px !important;
  overflow: visible;
}
.mat-sort-header-content {
  text-align: left !important;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 8px 0 8px;
}

.mat-mdc-header-row .mdc-data-table__header-row th {
  padding: 0 8px 0 8px;
}

.mat-sort-header-container {
  align-items: baseline !important;
}

/* CSS to show the sorting arrow all the time Start */
/* .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow:hover{
  opacity: 1 !important;
  transform: translateY(1%) !important;
} */
/* CSS to show the sorting arrow all the time end */

.mat-button-toggle-appearance-standard {
  background-color: #eceff3 !important;
}

.mat-button-toggle-group {
  background-color: #b9d4f1;
  max-width: 610px;
  overflow: visible !important;
}

.mat-button-toggle-group > .mat-button-toggle:not(:last-child) {
  border-right: 1px solid #3b6fb6;
}

/* .mat-button-toggle-group > .mat-button-toggle:nth-child(2) {
  border-right: 1px solid #3b6fb6;
} */

.mat-button-toggle {
  background-color: #eceff3;
  width: 500px;
}

.mat-button-toggle:hover {
  color: black;
  background-color: #d1e3f6;
}

.mat-button-toggle-checked {
  background-color: #b9d4f1;
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #b9d4f1 !important;
  width: 500px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 12px;
  line-height: 36px !important;
}

.mat-button-toggle-group-appearance-standard {
  border: 1px solid #3b6fb6 !important;
  border-radius: unset !important;
}

.training-wrapper .mat-mdc-tab-group .mdc-tab__text-label {
  font-size: 30px !important;
}

@media screen and ((width: 768px) or (width: 820px)) {
  .mat-mdc-header-row {
    display: none;
  }
  .mdc-data-table__row {
    height: 301px !important;
    border-bottom: 1px solid var(--neutral-300, #d0d0ce);
    background: #fff !important;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 4px;
    flex-direction: column;
  }
  .mdc-data-table__cell {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    align-items: center;
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .mat-column-afdbAccessions {
    border-bottom: 1px solid #d0d0ce !important;
    padding: 0 8px 0 8px;
  }

  .mat-column-uniprotDescriptions {
    border-bottom: 1px solid #d0d0ce !important;
    padding: 0 8px 0 8px;
  }
  .mat-mdc-cell {
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    padding-top: 8px;
    align-items: center;
  }
  .mat-mdc-row {
    width: 100%;
    display: grid;
    height: 100px;
    border: 1px solid #d0d0ce;
    margin-bottom: 8px;
  }
  .mmseqTable .mobile-view {
    display: block;
  }
  .mmseqTable .nonMobile {
    display: none;
  }
  .mmseqTable .mobile-view-title {
    min-width: 30%;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding-top: 5px;
  }
  .mat-column-averagePlddt {
    border-bottom: none !important;
  }

  .mmseqTable a {
    width: 149px;
    margin-right: 10px;
  }
}

@media (min-width: 1440px) and (min-height: 1080px) {
  .mmseqTable .mobile-view {
    display: none;
  }
}

@media (min-width: 1920px) and (min-height: 1080px) {
  .mmseqTable .mobile-view {
    display: none;
  }
}
@media (min-width: 1024px) and (min-height: 1366px) {
  .mat-mdc-header-row {
    display: none;
  }
  .mdc-data-table__row {
    height: 270px !important;
    border-bottom: 1px solid #d0d0ce;
    background: #fff;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 4px;
    flex-direction: column;
  }
  .mdc-data-table__cell {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    align-items: center;
  }
  .mat-mdc-cell {
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    padding-top: 8px;
    align-items: flex-end;
    padding-bottom: 8px;
    align-items: center;
  }
  .mat-mdc-row {
    width: 100%;
    display: grid;
    height: 100px;
    border: 1px solid #d0d0ce;
    margin-bottom: 8px;
  }
  .mmseqTable .mobile-view {
    display: block;
  }
  .mmseqTable .mobile-view-title {
    min-width: 18%;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding-top: 5px;
  }
  .mmseqTable .nonMobile {
    display: none;
  }
  .mat-column-averagePlddt {
    border-bottom: none !important;
  }
  .mmseqTable a {
    width: 139px;
    margin-right: 10px;
  }
}

@media (width: 640px) and (height: 1080px) {
  .mmseqTable .mobile-view {
    display: none;
  }
}

@media (width: 1080px) and (height: 1080px) {
  .mmseqTable .mobile-view {
    display: none;
  }
}
@media (width: 990px) and (height: 1080px) {
  .mmseqTable .mobile-view {
    display: none;
  }
}

@media screen and (max-width: 39.9375em) {
  .mat-mdc-header-row {
    display: none;
  }
  .mdc-data-table__row {
    height: 344px !important;
    border-bottom: 1px solid #d0d0ce;
    background: #fff;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 4px;
    flex-direction: column;
  }
  .mdc-data-table__cell {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    align-items: center;
  }
  .mat-mdc-cell {
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    padding-top: 8px;
    align-items: flex-end;
    padding-bottom: 8px;
    align-items: center;
  }
  .mat-mdc-row {
    width: 100%;
    display: grid;
    height: 100px;
    border: 1px solid #d0d0ce;
    margin-bottom: 8px;
  }
  .mmseqTable .mobile-view {
    display: block;
  }
  .mmseqTable .mobile-view-title {
    min-width: 30%;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding-top: 5px;
  }
  .mmseqTable .nonMobile {
    display: none;
  }
  .mat-column-averagePlddt {
    border-bottom: none !important;
  }
  .mmseqTable a {
    width: 139px;
    margin-right: 10px;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 12px;
    line-height: 36px !important;
    font-size: 14px !important;
  }
}

.groupLevel {
  background-color: darkgray;
  font-size: 25px;
  width: 150px;
}
.buttonToggleLevel {
  color: beige;
  width: 150px;
}
.pvTracks_0 {
  display: none;
}
.pvVariantPlotSection svg {
  box-sizing: unset !important;
}
#plotAreaClip {
  display: none !important;
}

.category-label {
  background-color: #b9d4f1 !important;
}

.track-label {
  background-color: #eceff3 !important;
}

/* Hide checkboxes under protvistaCol1 */
.protvistaCol1
  protvista-filter
  > div
  > div
  > div:nth-child(4)
  protvista-checkbox {
  display: none !important;
}

/* Hide the <h5> element for protvistaCol1 */
.protvistaCol1 protvista-filter > div > div > h5:nth-child(3) {
  display: none !important;
}

.protvistaCol1
  protvista-filter
  > div
  > div
  > div:nth-child(2)
  protvista-checkbox {
  display: none !important;
}

/* Hide the <h5> element for protvistaCol1 */
.protvistaCol1 protvista-filter > div > div > h5:nth-child(1) {
  display: none !important;
}

.pvHideOptionsTable tr:first-child {
  display: none !important;
}

/* Uncertain */

.uncertainBox .mat-mdc-slider {
  width: 170px !important;
}

.uncertainBox .mdc-slider .mdc-slider__thumb-knob {
  background-color: #a9abaa !important;
  border-radius: unset !important;
  width: 12px !important;
  height: 20px !important;
  padding: 2px 4px !important;
  border-style: none !important;
  border-width: calc(var(--mdc-slider-handle-height, 20px) / 2)
    calc(var(--mdc-slider-handle-width, 20px) / 2);
}

.uncertainBox .mat-mdc-slider .mdc-slider__track {
  height: 4px !important;
}

.uncertainBox .mdc-slider__thumb:last-child > .mdc-slider__thumb-knob {
  background-color: #a9abaa !important;
}

.uncertainBox .mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: #a9abaa;
  background-color: #a9abaa;
}

.uncertainBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  display: none !important;
  background-color: #d0d0ce !important;
  opacity: 0.24 !important;
  border-radius: none;
}

.uncertainBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  background-color: #d0d0ce;
  display: block !important;
  border-radius: 0px;
  height: 4px;
}
.uncertainBox
  .mdc-slider--disabled
  .mdc-slider__track
  .mdc-slider__track--active {
  display: none !important;
}

.uncertainBox .mdc-slider--disabled .mdc-slider__track {
  background-color: #a9abaa !important;
  height: 4px !important;
  border-radius: none;
}

/* Benign */

.benignBox .mat-mdc-slider {
  width: 202px !important;
}

.benignBox .mdc-slider .mdc-slider__thumb-knob {
  background-color: #2166ac !important;
  border-radius: unset !important;
  width: 12px !important;
  height: 20px !important;
  padding: 2px 4px !important;
  border-style: none !important;
  border-width: calc(var(--mdc-slider-handle-height, 20px) / 2)
    calc(var(--mdc-slider-handle-width, 20px) / 2);
}

.benignBox .mat-mdc-slider .mdc-slider__track {
  height: 4px !important;
}

.benignBox .mdc-slider__thumb:last-child > .mdc-slider__thumb-knob {
  background-color: #2166ac !important;
}

.benignBox .mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: #2166ac;
  background-color: #2166ac;
}

.benignBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  display: none !important;
  background-color: #d0d0ce !important;
  opacity: 0.24 !important;
  border-radius: none;
}

.benignBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  background-color: #d0d0ce;
  display: block !important;
  border-radius: 0px;
  height: 4px;
}
.benignBox .mdc-slider--disabled .mdc-slider__track .mdc-slider__track--active {
  display: none !important;
}

.benignBox .mdc-slider--disabled .mdc-slider__track {
  background-color: #2166ac !important;
  height: 4px !important;
  border-radius: none;
}

/* Pathogenic */
.pathogenicBox .mat-mdc-slider {
  width: 236px !important;
}

.pathogenicBox .mdc-slider .mdc-slider__thumb-knob {
  background-color: #b2182b !important;
  border-radius: unset !important;
  width: 12px !important;
  height: 20px !important;
  padding: 2px 4px !important;
  border-style: none !important;
  border-width: calc(var(--mdc-slider-handle-height, 20px) / 2)
    calc(var(--mdc-slider-handle-width, 20px) / 2);
}

.pathogenicBox .mat-mdc-slider .mdc-slider__track {
  height: 4px !important;
}

.pathogenicBox .mdc-slider__thumb:last-child > .mdc-slider__thumb-knob {
  background-color: #b2182b !important;
}

.pathogenicBox .mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: #b2182b;
  background-color: #b2182b;
}

.pathogenicBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  display: none !important;
  background-color: #d0d0ce !important;
  opacity: 0.24 !important;
  border-radius: none;
}

.pathogenicBox .mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  background-color: #d0d0ce;
  display: block !important;
  border-radius: 0px;
  height: 4px;
}
.pathogenicBox
  .mdc-slider--disabled
  .mdc-slider__track
  .mdc-slider__track--active {
  display: none !important;
}

.pathogenicBox .mdc-slider--disabled .mdc-slider__track {
  background-color: #b2182b !important;
  height: 4px !important;
  border-radius: none;
}

.mat-mdc-slider .mdc-slider__value-indicator {
  color: #373a36 !important;
}

.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 33px !important;
}

.mat-mdc-slider .mdc-slider__value-indicator {
  background-color: unset !important;
}

.heatmap-tooltip-content,
.heatmap-pinned-tooltip-content {
  border: none !important;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030 !important;
}
.heatmap-pinned-tooltip-content {
  border: none !important;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030 !important;
}

.vf-button--secondary:hover {
  opacity: 1 !important;
  color: #3b6fb6 !important;
}

.vf-button--tertiary:hover {
  /* -webkit-box-shadow: 4px 4px 0 var(--vf-button-shadow-background-color, var(--vf-button__color__background--default-dark)),2px 2px 4px rgba(0,0,0,.25),-5px -5px rgba(0,0,0,0)e !important;
   box-shadow: 4px 4px 0 var(--vf-button-shadow-background-color, var(--vf-button__color__background--default-dark)),2px 2px 4px rgba(0,0,0,.25),-5px -5px rgba(0,0,0,0) !important;  */
  /* -webkit-transform: none !important;
  transform: none !important;
  -webkit-transition: none !important;
  transition: none !important; */

  background: #707372 !important;
  border-color: #707372 !important;
  opacity: 1 !important;
}
